import React from 'react';
import { Block } from 'baseui/block';
import { Display1, Paragraph1, Paragraph2, Paragraph3 } from 'baseui/typography';
import Layout from 'components/layout';

export default () => {
  return (
    <Layout>
      <Block>
        <Paragraph1>Privacy Policy</Paragraph1>
        <Paragraph3>This Application collects some Personal Data from its Users.</Paragraph3>
        <Paragraph2>DATA CONTROLLER AND OWNER</Paragraph2>
        <Paragraph3>One More Watches.</Paragraph3>
        <Paragraph3>support@onemorewatches.com</Paragraph3>
        <Paragraph2>TYPES OF DATA COLLECTED</Paragraph2>
        <Paragraph3>
          Among the types of Personal Data that this Application collects,
          by itself or through third parties, there are: Cookie, Usage data
          and ZIP/Postal code.
        </Paragraph3>
        <Paragraph3>
          Other Personal Data collected may be described in other sections
          of this privacy policy or by dedicated explanation text
          contextually with the Data collection. The Personal Data may be
          freely provided by the User, or collected automatically when using
          this Application. Any use of Cookies - or of other tracking tools
          - by this Application or by the owners of third party services
          used by this Application, unless stated otherwise, serves to
          identify Users and remember their preferences, for the sole
          purpose of providing the service required by the User. Failure to
          provide certain Personal Data may make it impossible for this
          Application to provide its services.
        </Paragraph3>

        <Paragraph3>
          Users are responsible for any Personal Data of third parties
          obtained, published or shared through this Application and confirm
          that they have the third party's consent to provide the Data to
          the Owner.
        </Paragraph3>
        <Paragraph2>MODE AND PLACE OF PROCESSING THE DATA</Paragraph2>
        <Paragraph2>METHODS OF PROCESSING</Paragraph2>
        <Paragraph3>
          The Data Controller processes the Data of Users in a proper manner
          and shall take appropriate security measures to prevent
          unauthorized access, disclosure, modification, or unauthorized
          destruction of the Data. The Data processing is carried out using
          computers and/or IT enabled tools, following organizational
          procedures and modes strictly related to the purposes indicated.
          In addition to the Data Controller, in some cases, the Data may be
          accessible to certain types of persons in charge, involved with
          the operation of the site (administration, sales, marketing,
          legal, system administration) or external parties (such as third
          party technical service providers, mail carriers, hosting
          providers, IT companies, communications agencies) appointed, if
          necessary, as Data Processors by the Owner. The updated list of
          these parties may be requested from the Data Controller at any
          time.
        </Paragraph3>
        <Paragraph2>PLACE</Paragraph2>
        <Paragraph3>
          The Data is processed at the Data Controller's operating offices
          and in any other places where the parties involved with the
          processing are located. For further information, please contact
          the Data Controller.
        </Paragraph3>
        <Paragraph2>RETENTION TIME</Paragraph2>
        <Paragraph3>
          The Data is kept for the time necessary to provide the service
          requested by the User, or stated by the purposes outlined in this
          document, and the User can always request that the Data Controller
          suspend or remove the data.
        </Paragraph3>
        <Paragraph2>THE USE OF THE COLLECTED DATA</Paragraph2>
        <Paragraph3>
          The Data concerning the User is collected to allow the Owner to
          provide its services, as well as for the following purposes:
          Access to third party services' accounts, Analytics, Interaction
          with external social networks and platforms, Handling payments,
          Displaying content from external platforms and Registration and
          authentication.
        </Paragraph3>
        <Paragraph3>
          The Personal Data used for each purpose is outlined in the
          specific sections of this document.
        </Paragraph3>
        <Paragraph2>DETAILED INFORMATION ON THE PROCESSING OF PERSONAL DATA</Paragraph2>
        <Paragraph3>
          Personal Data is collected for the following purposes and using
          the following services:
        </Paragraph3>

        <ul>
          <li>
            <Paragraph2>ACCESS TO THIRD PARTY SERVICES' ACCOUNTS</Paragraph2>
            <Paragraph3>
              These services allow this Application to access Data from your
              account on a third party service and perform actions with it.
              These services are not activated automatically, but require
              explicit authorization by the User.
            </Paragraph3>
            <Paragraph3>Access to the Twitter account (This Application)</Paragraph3>
            <Paragraph3>
              This service allows this Application to connect with the
              User's account on the Twitter social network, provided by
              Twitter Inc.
            </Paragraph3>
            <Paragraph3>
              Personal Data collected: Various types of Data as specified in
              the privacy policy of the service.
            </Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a href="http://twitter.com/privacy" rel="nofollow">
                Privacy Policy
              </a>
            </Paragraph3>
          </li>
          <li>
            <Paragraph2>ANALYTICS</Paragraph2>
            <Paragraph3>
              The services contained in this section enable the Owner to
              monitor and analyze web traffic and can be used to keep track
              of User behavior.
            </Paragraph3>
            <Paragraph3>Google Analytics (Google)</Paragraph3>
            <Paragraph3>
              Google Analytics is a web analysis service provided by Google
              Inc. (“Google”). Google utilizes the Data collected to track
              and examine the use of this Application, to prepare reports on
              its activities and share them with other Google services.
              Google may use the Data collected to contextualize and
              personalize the ads of its own advertising network.
            </Paragraph3>
            <Paragraph3>Personal Data collected: Cookie and Usage data.</Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a
                href="http://www.google.com/intl/en/policies/privacy/"
                rel="nofollow"
              >
                Privacy Policy
              </a>{' '}
              –{' '}
              <a
                href="http://tools.google.com/dlpage/gaoptout?hl=en"
                rel="nofollow"
              >
                Opt Out
              </a>
            </Paragraph3>
          </li>
          <li>
            <Paragraph2>DISPLAYING CONTENT FROM EXTERNAL PLATFORMS</Paragraph2>
            <Paragraph3>
              These services allow you to view content hosted on external
              platforms directly from the pages of this Application and
              interact with them. If a service of this kind is installed, it
              may still collect web traffic data for the pages where the
              service is installed, even when users do not use it.
            </Paragraph3>
            <Paragraph3>Instagram widget (Instagram, Inc.)</Paragraph3>
            <Paragraph3>
              Instagram is an image visualization service provided by
              Instagram, Inc. that allows this Application to incorporate
              content of this kind on its pages.
            </Paragraph3>
            <Paragraph3>Personal Data collected: Cookie and Usage data.</Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a
                href="http://instagram.com/about/legal/privacy/"
                rel="nofollow"
              >
                Privacy Policy
              </a>
            </Paragraph3>
          </li>
          <li>
            <Paragraph2>HANDLING PAYMENTS</Paragraph2>
            <Paragraph3>
              Payment processing services enable this Application to process
              payments by credit card, bank transfer or other means. To
              ensure greater security, this Application shares only the
              information necessary to execute the transaction with the
              financial intermediaries handling the transaction. Some of
              these services may also enable the sending of timed messages
              to the User, such as emails containing invoices or
              notifications concerning the payment.
            </Paragraph3>
            <Paragraph3>Stripe</Paragraph3>
            <Paragraph3>Stripe is a payment service provided by Stripe.com.</Paragraph3>
            <Paragraph3>
              Personal Data collected: Various types of Data as specified in
              the privacy policy of the service.
            </Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a href="https://stripe.com/us/privacy" rel="nofollow">
                Privacy Policy
              </a>
            </Paragraph3>
          </li>
          <li>
            <Paragraph2>INTERACTION WITH EXTERNAL SOCIAL NETWORKS AND PLATFORMS</Paragraph2>
            <Paragraph3>
              These services allow interaction with social networks or other
              external platforms directly from the pages of this
              Application. The interaction and information obtained by this
              Application are always subject to the User’s privacy settings
              for each social network. If a service enabling interaction
              with social networks is installed it may still collect traffic
              data for the pages where the service is installed, even when
              Users do not use it.
            </Paragraph3>
            <Paragraph3>Google+ +1 button and social widgets (Google)</Paragraph3>
            <Paragraph3>
              The Google+ +1 button and social widgets are services allowing
              interaction with the Google+ social network provided by Google
              Inc.
            </Paragraph3>
            <Paragraph3>Personal Data collected: Cookie and Usage data.</Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a
                href="http://www.google.com/intl/en/policies/privacy/"
                rel="nofollow"
              >
                Privacy Policy
              </a>
            </Paragraph3>
            <Paragraph3>Facebook Like button and social widgets (Facebook)</Paragraph3>
            <Paragraph3>
              The Facebook Like button and social widgets are services
              allowing interaction with the Facebook social network provided
              by Facebook Inc.
            </Paragraph3>
            <Paragraph3>Personal Data collected: Cookie and Usage data.</Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a
                href="http://www.facebook.com/privacy/explanation.php"
                rel="nofollow"
              >
                Privacy Policy
              </a>
            </Paragraph3>
            <Paragraph3>Pinterest “Pin it” button and social widgets (Pinterest)</Paragraph3>
            <Paragraph3>
              The Pinterest “Pin it” button and social widgets are services
              allowing interaction with the Pinterest platform provided by
              Pinterest Inc.
            </Paragraph3>
            <Paragraph3>Personal Data collected: Cookie and Usage data.</Paragraph3>
            <Paragraph3>
              Place of processing : USA –{' '}
              <a href="http://about.pinterest.com/privacy/" rel="nofollow">
                Privacy Policy
              </a>
            </Paragraph3>
          </li>
          <li>
            <Paragraph2>REGISTRATION AND AUTHENTICATION</Paragraph2>
            <Paragraph3>
              By registering or authenticating, Users allow this Application
              to identify them and give them access to dedicated services.
              Depending on what is described below, third parties may
              provide registration and authentication services. In this
              case, this Application will be able to access some Data,
              stored by these third party services, for registration or
              identification purposes.
            </Paragraph3>
            <Paragraph3>Direct registration (This Application)</Paragraph3>
            <Paragraph3>
              The User registers by filling out the registration form and
              providing the Personal Data directly to this Application.
            </Paragraph3>
            <Paragraph3>Personal Data collected: ZIP/Postal code.</Paragraph3>
          </li>
        </ul>
        <Paragraph2>FURTHER INFORMATION ABOUT PERSONAL DATA</Paragraph2>
        <ul>
          <li>
            <Paragraph2>SELLING GOODS AND SERVICES ONLINE</Paragraph2>
            <Paragraph3>
              The Personal Data collected are used to provide the User with
              services or to sell goods, including payment and possible
              delivery. The Personal Data collected to complete the payment
              may include the credit card, the bank account used for the
              transfer, or any other means of payment envisaged. The kind of
              Data collected by this Application depends on the payment
              system used.
            </Paragraph3>
          </li>
        </ul>
        <Paragraph2>INFORMATION ABOUT COOKIES</Paragraph2>
        <ul>
          <li>
            <Paragraph2>ANALYTICS</Paragraph2>
            <Paragraph3>
              These Cookies are necessary for the functioning of the
              services filed under the section ”Analytics”.
            </Paragraph3>
            <Paragraph3>_ut*, _ga*</Paragraph3>
            <Paragraph3>Google Analytics Cookies.</Paragraph3>
          </li>
        </ul>
        <Paragraph3>
          Since the installation of third party Cookies and other tracking
          systems through the services used within this Application can not
          be technically controlled by the Owner, any specific references to
          Cookies and tracking systems installed by third parties are to be
          considered indicative. In order to obtain complete information,
          consult the privacy policy for the respective third party services
          listed in this document.
        </Paragraph3>
        <Paragraph2>ADDITIONAL INFORMATION ABOUT DATA COLLECTION AND PROCESSING</Paragraph2>
        <Paragraph2>LEGAL ACTION</Paragraph2>
        <Paragraph3>
          The User's Personal Data may be used for legal purposes by the
          Data Controller, in Court or in the stages leading to possible
          legal action arising from improper use of this Application or the
          related services. The User declares to be aware that the Data
          Controller may be required to reveal personal data upon request of
          public authorities.
        </Paragraph3>
        <Paragraph2>ADDITIONAL INFORMATION ABOUT USER'S PERSONAL DATA</Paragraph2>
        <Paragraph3>
          In addition to the information contained in this privacy policy,
          this Application may provide the User with additional and
          contextual information concerning particular services or the
          collection and processing of Personal Data upon request.
        </Paragraph3>
        <Paragraph2>SYSTEM LOGS AND MAINTENANCE</Paragraph2>
        <Paragraph3>
          For operation and maintenance purposes, this Application and any
          third party services may collect files that record interaction
          with this Application (System Logs) or use for this purpose other
          Personal Data (such as IP Address).
        </Paragraph3>
        <Paragraph2>INFORMATION NOT CONTAINED IN THIS POLICY</Paragraph2>
        <Paragraph3>
          More details concerning the collection or processing of Personal
          Data may be requested from the Data Controller at any time. Please
          see the contact information at the beginning of this document.
        </Paragraph3>
        <Paragraph2>THE RIGHTS OF USERS</Paragraph2>
        <Paragraph3>
          Users have the right, at any time, to know whether their Personal
          Data has been stored and can consult the Data Controller to learn
          about their contents and origin, to verify their accuracy or to
          ask for them to be supplemented, cancelled, updated or corrected,
          or for their transformation into anonymous format or to block any
          data held in violation of the law, as well as to oppose their
          treatment for any and all legitimate reasons. Requests should be
          sent to the Data Controller at the contact information set out
          above.
        </Paragraph3>
        <Paragraph3>
          This Application does not support “Do Not Track” requests. To
          determine whether any of the third party services it uses honor
          the “Do Not Track” requests, please read their privacy policies.
        </Paragraph3>
        <Paragraph2>CHANGES TO THIS PRIVACY POLICY</Paragraph2>
        <Paragraph3>
          The Data Controller reserves the right to make changes to this
          privacy policy at any time by giving notice to its Users on this
          page. It is strongly recommended to check this page often,
          referring to the date of the last modification listed at the
          bottom. If a User objects to any of the changes to the Policy, the
          User must cease using this Application and can request that the
          Data Controller removes the Personal Data. Unless stated
          otherwise, the then-current privacy policy applies to all Personal
          Data the Data Controller has about Users.
        </Paragraph3>
        <Paragraph2>INFORMATION ABOUT THIS PRIVACY POLICY</Paragraph2>
        <Paragraph3>
          The Data Controller is responsible for this privacy policy,
          prepared starting from the modules provided by Iubenda and hosted
          on Iubenda's servers.
        </Paragraph3>
        <Paragraph2>DEFINITIONS AND LEGAL REFERENCES</Paragraph2>
        <Paragraph3>Personal Data (or Data)</Paragraph3>
        <Paragraph3>
          Any information regarding a natural person, a legal person, an
          institution or an association, which is, or can be, identified,
          even indirectly, by reference to any other information, including
          a personal identification number.
        </Paragraph3>
        <Paragraph3>Usage Data</Paragraph3>
        <Paragraph3>
          Information collected automatically from this Application (or
          third party services employed in this Application ), which can
          include: the IP addresses or domain names of the computers
          utilized by the Users who use this Application, the URI addresses
          (Uniform Resource Identifier), the time of the request, the method
          utilized to submit the request to the server, the size of the file
          received in response, the numerical code indicating the status of
          the server's answer (successful outcome, error, etc.), the country
          of origin, the features of the browser and the operating system
          utilized by the User, the various time details per visit (e.g.,
          the time spent on each page within the Application) and the
          details about the path followed within the Application with
          special reference to the sequence of pages visited, and other
          parameters about the device operating system and/or the User's IT
          environment.
        </Paragraph3>
        <Paragraph3>User</Paragraph3>
        <Paragraph3>
          be authorized by the Data Subject, to whom the Personal Data
          refer.
        </Paragraph3>
        <Paragraph3>Data Subject</Paragraph3>
        <Paragraph3>The legal or natural person to whom the Personal Data refers.</Paragraph3>
        <Paragraph3>Data Processor (or Data Supervisor)</Paragraph3>
        <Paragraph3>
          The natural person, legal person, public administration or any
          other body, association or organization authorized by the Data
          Controller to process the Personal Data in compliance with this
          privacy policy.
        </Paragraph3>
        <Paragraph3>Data Controller (or Owner)</Paragraph3>
        <Paragraph3>
          The natural person, legal person, public administration or any
          other body, association or organization with the right, also
          jointly with another Data Controller, to make decisions regarding
          the purposes, and the methods of processing of Personal Data and
          the means used, including the security measures concerning the
          operation and use of this Application. The Data Controller, unless
          otherwise specified, is the Owner of this Application.
        </Paragraph3>
        <Paragraph3>This Application</Paragraph3>
        <Paragraph3>
          The hardware or software tool by which the Personal Data of the
          User is collected.
        </Paragraph3>
        <Paragraph3>Cookie</Paragraph3>
        <Paragraph3>Small piece of data stored in the User's device.</Paragraph3>
        <Paragraph3>Legal information</Paragraph3>
        <Paragraph3>
          Notice to European Users: this privacy statement has been prepared
          in fulfillment of the obligations under Art. 10 of EC Directive n.
          95/46/EC, and under the provisions of Directive 2002/58/EC, as
          revised by Directive 2009/136/EC, on the subject of Cookies.
        </Paragraph3>
        <Paragraph3>This privacy policy relates solely to this Application.</Paragraph3>
      </Block>
    </Layout>
  );
};
